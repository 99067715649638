
import { defineComponent, onMounted } from 'vue'
import Topbar from '@/components/Topbar/Topbar.vue'
import SideNav from '@/components/SideNav/SideNav.vue'
import '@progress/kendo-ui/js/kendo.switch'
import { KendoSwitch } from '@progress/kendo-inputs-vue-wrapper'
import ControlWrapper from '@/components/Control/ControlWrapper.vue'
import ControlButton from '@/components/Control/ControlButton.vue'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import { Grid } from '@progress/kendo-vue-grid'
import useGridInADSetting from '@/composable/useGridInADSetting'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import NewLegalDomainForm from '@/components/Form/ADSettingView/NewLegalDomainForm.vue'

export default defineComponent({
  components: {
    Topbar,
    SideNav,
    KendoSwitch,
    ControlWrapper,
    ControlButton,
    GridWrapper,
    Grid,
    Dialog,
    NewLegalDomainForm
  },
  setup() {
    const {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      pageChangeHandler
    } = useGridInADSetting()

    onMounted(() => {
      dataItems.value = createRandomData(32) as never[]
    })

    // Dialog 相關
    const {
      show: showNewLegalDomain,
      toggle: toggleNewLegalDomain
    } = useToggle()
    return {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      pageChangeHandler,
      showNewLegalDomain,
      toggleNewLegalDomain
    }
  }
})
